<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">{{  $t("message.edit_m", {
                                m: $t("message.managerTypes"),
                        }) }}
							</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item
                                    :label="$t('message.name')"
                                    class="label_mini"
                                    prop="name"
                                >
                                    <el-input
                                        :placeholder="$t('message.name')"
                                        v-model="form.name"
                                        size="medium"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- end-col -->
									<el-col :span="24"> 
										<el-form-item :label="$t('message.bonuses of manager type for first deal')">
											<el-table :data="items_2" border style="width: 100%">
												<el-table-column  label="#" type="index" width="180">
												</el-table-column>
												<el-table-column :label="$t('message.from') + ' %'" width="180">
													<template slot-scope="item">
														<crm-only-number-input
															v-model="item.row.from"
															:inputValue="item.row.from"
														></crm-only-number-input>
													</template>
												</el-table-column>
												<el-table-column  :label="$t('message.before') + ' %'" width="180">
													<template slot-scope="item">
														<crm-only-number-input
															v-model="item.row.until"
															:inputValue="item.row.until"
														></crm-only-number-input>
													</template>
												</el-table-column>
												<el-table-column  :label="$t('message.bonuses') + ' %'">
													<template slot-scope="item">
														<crm-only-number-input
															v-model="item.row.bonus"
															:inputValue="item.row.bonus"
														></crm-only-number-input>
													</template>
												</el-table-column>
												<el-table-column  :label="$t('message.delete')">
													<template slot-scope="item">
														<el-button type="danger" @click="removeRow(item.row, true)" :disabled="item.row.id ? true : false" icon="el-icon-delete delete-icon" circle></el-button>
													</template>
												</el-table-column>
											</el-table>
										</el-form-item>
									</el-col>
									<div>
										<el-button type="primary" @click="addRules(true)">{{ $t('message.add') + ' ' + $t('message.bonuses') }}</el-button>
						  			</div>
									<el-col :span="24"> 
										<el-form-item :label="$t('message.bonuses_manager_type')">
											<el-table :data="items" border style="width: 100%">
												<el-table-column  label="#" type="index" width="180">
												</el-table-column>
												<el-table-column :label="$t('message.from') + ' %'" width="180">
													<template slot-scope="item">
														<crm-only-number-input
															v-model="item.row.from"
															:inputValue="item.row.from"
														></crm-only-number-input>
													</template>
												</el-table-column>
												<el-table-column  :label="$t('message.before') + ' %'" width="180">
													<template slot-scope="item">
														<crm-only-number-input
															v-model="item.row.until"
															:inputValue="item.row.until"
														></crm-only-number-input>
													</template>
												</el-table-column>
												<el-table-column  :label="$t('message.bonuses') + ' %'">
													<template slot-scope="item">
														<crm-only-number-input
															v-model="item.row.bonus"
															:inputValue="item.row.bonus"
														></crm-only-number-input>
													</template>
												</el-table-column>
												<el-table-column  :label="$t('message.delete')">
													<template slot-scope="item">
														<el-button type="danger" @click="removeRow(item.row, false)" :disabled="item.row.id ? true : false" icon="el-icon-delete delete-icon" circle></el-button>
													</template>
												</el-table-column>
											</el-table>
										</el-form-item>
									</el-col>	
									<div>
										<el-button type="primary" @click="addRules(false)">{{ $t('message.add') + ' ' + $t('message.bonuses') }}</el-button>
						  			</div>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import managerTypes from '@/utils/mixins/models/managerTypes';
import show from '../../../utils/mixins/show';

export default {
	mixins: [managerTypes, show],
   methods: {
		...mapActions({
			save: "managerTypes/update",
			show: 'managerTypes/show'
		}),
		fetchData() {
			if (!this.loadingData && this.selectedItem) {  
				this.loadingData=true;        
				this.show(this.selectedItem.id).then(res => {   
					this.loadingData = false;
				   this.items = JSON.parse(JSON.stringify(res.managerType.items));  
					this.items_2 = JSON.parse(JSON.stringify(res.managerType.items_2));      
				}).catch(err => {
					this.loadingData = false;
				});
			}
     },
   },
};
</script>
<style>
.delete-icon{
	color: white;
}
</style>
