<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.managerTypes") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

									   <th v-if="columns.bonuses_manager_type.show">
                                {{ columns.bonuses_manager_type.title }}
                           	 </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.name.show">
                                <crm-input
                                    :placeholder="columns.name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                ></crm-input>
                            </th>
									   <th v-if="columns.bonuses_manager_type.show">
                                <crm-input
                                    :placeholder="columns.bonuses_manager_type.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
												:disabled="true"
                                ></crm-input>
                            </th>
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="managerType in list"
                            :key="managerType.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ managerType.id }}
                            </td>

                            <td v-if="columns.name.show">
                                {{ managerType.name }}
                            </td>
									 <td v-if="columns.name.show">
										<el-button type="text"  @click="showTypes(managerType.rules)" style="margin:0;padding:0">{{ $t('message.view') }}</el-button>
                            </td>
                            <td v-if="columns.created_at.show">
                                {{ managerType.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ managerType.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="managerType"
                                    :actions="actions"
                                    :permissionShow="'managerTypes.update'"
                                    :permissionDestroy="'managerTypes.delete'"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
						  append-to-body
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    size="70%"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
            
                        <crm-create
                            ref="drawerCreateChild"
                            drawer="drawerCreate"
                        >
                        </crm-create>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
		   <el-dialog :title="$t('message.bonuses_manager_type')" :visible.sync="dialogTableVisible">
				<el-table :data="rules" border>
					<el-table-column property="id" label="ID" width="100"></el-table-column>
					<el-table-column property="from" :label="$t('message.from') + ' %'"></el-table-column>
					<el-table-column property="until" :label="$t('message.before') + ' %'"></el-table-column>
					<el-table-column property="bonus" :label="$t('message.bonuses') + ' %'"></el-table-column>
				</el-table>
			</el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
    name: "countries",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
    },

    data() {
        return {
			dialogTableVisible: false,
			rules: []
		  };
    },

    computed: {
        ...mapGetters({
            list: "managerTypes/list",
            columns: "managerTypes/columns",
            pagination: "managerTypes/pagination",
            filter: "managerTypes/filter",
            sort: "managerTypes/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "managerTypes/index",
            setPagination: "managerTypes/setPagination",
            updateSort: "managerTypes/updateSort",
            updateFilter: "managerTypes/updateFilter",
            updateColumn: "managerTypes/updateColumn",
            updatePagination: "managerTypes/updatePagination",
            show: "managerTypes/show",
            empty: "managerTypes/empty",
            delete: "managerTypes/destroy",
            refreshData: "managerTypes/refreshData"
        }),
		   showTypes(data){
				this.dialogTableVisible = true;
				this.rules = data;
			},
    },
	
    beforeRouteLeave (to, from, next) {
        this.$store.commit('managerTypes/EMPTY_LIST');
		  this.rules = [];
        next()
    },
};
</script>

