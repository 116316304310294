
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import crmOnlyNumberInput from '../../../components/crm/crm-only-number-input';

export default {
	components: { crmOnlyNumberInput },
	mixins: [form, drawer],
	data() {
		 return {
		  items: [],
		  items_2: []
		 };
	},
	computed: {
		 ...mapGetters({
			  rules: "managerTypes/rules",
			  model: "managerTypes/model",
			  columns: "managerTypes/columns",
		 }),
	},
	methods:{
		submit(close = true) {
			if (_.isEmpty(this.items) || _.isEmpty(this.items_2)){
				return this.$message({
					type: "warning",
					message: this.$t('message.bonuses_manager_type') + ' ' +  this.$t('message.The_field_must_not_be_empty'),
			  });
			}
			this.form['items'] = this.items.concat(this.items_2);
			this.$refs["form"].validate((valid) => {
				 if (valid) {
					this.loadingButton = true;
					this.save(this.form)
						.then((res) => {
								this.loadingButton = false;
								this.$alert(res);
								this.parent().listChanged();
								if (close) this.close();
						})
						.catch((err) => {
								this.loadingButton = false;
								this.$alert(err);
						});
				 }
			});
	  },
	  addRules(status){
		if (status){
			this.items_2.push({
				id: null,
				from: 0,
				until: 0,
				bonus: 0,
				first_deal: true
			});
		}
		else {
			this.items.push({
				id: null,
				from: 0,
				until: 0,
				bonus: 0,
				first_deal: false
			});
		}
	  },
		removeRow(row, status){
			if (status) {
				if (this.items_2.length > 0)
					this.items_2.splice(this.items_2.indexOf(row), 1);
			}
			else {
				if (this.items.length > 0)
					this.items.splice(this.items.indexOf(row), 1);
			}
		},
		afterLeave(){
			this.items = [];
			this.items_2 = [];
		}
	}
}